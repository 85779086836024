<template>
    <div>

<DoneAlert :msg="$t('Data has been updated')" :visible="ShowDoneAlert" />
 <ErrorAlert :msg="errorMgs" :visible="ShowError" />
 <b-container  class=" mt-5" >
<FormSkeleton  v-if="!showForm" />

<b-card bg-variant="light" v-if="showForm" :header="competition.name" class="text-right">
<br>   
<b-form @submit="onSubmit"  v-if="show"  enctype="multipart/form-data">
<b-row>
    <b-col cols="6"  > 
      <b-list-group>
      <b-list-group-item>اسم المسابفة: {{competition.name}}</b-list-group-item>
      <b-list-group-item>المتقدم: {{user.name}}</b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col cols="6"  > 
      <b-list-group>
        <b-list-group-item>التصويت الايجابى: {{upvote}}</b-list-group-item>
        <b-list-group-item>التصويت السلبى: {{downvote}}</b-list-group-item>
      </b-list-group>
    </b-col>
</b-row>    
<b-row class="mt-5">
    <b-col cols="12"  > 
    <div>
    <b-alert :show="ShowError" dismissible variant="danger">
      
    {{errorMgs}}

    </b-alert>
     </div>
     </b-col>
</b-row>
  <b-row v-if="previewImage" class="mb-3 pb-5 border-bottom">
          <b-col cols="6" > 
            <h5 >{{$t('image')}}</h5>
          <b-img :src="previewImage" fluid></b-img>
             </b-col>
        </b-row>
<b-row align-h="center">
   <!--image-->
    
<b-col cols="12" lg="6" xl="6" > 

    
      <!-- input -->
      <b-form-group  :label="$t('Title')" label-for="input-1" :class="{ 'form-group--error': $v.form.name.$error }">
        <b-form-input  v-model.trim="form.name" @input="setTouched('name')" type="text" required ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.name.$error"  :state="$v.form.name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
 
      
   </b-col>

   <b-col cols="12" lg="6" xl="6" > 

       <!-- input -->
      <b-form-group :label="$t('Video Link')" label-for="input-1" :class="{ 'form-group--error': $v.form.url.$error }">
        <b-form-input   v-model.trim="form.url" @input="setTouched('url')" type="texy" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.url.$error" :state="$v.form.url.required"> {{$t('required_form')}} </b-form-invalid-feedback>
    
         <div v-if="errorsBack.url" >
            <b-form-invalid-feedback v-for="error in errorsBack.url" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
      
     
    
 
   </b-col>
   </b-row>
   <b-row>
    <b-col cols="12"  >

         <!-- input -->
      <b-form-group  :label="$t('description')" label-for="input-1" :class="{ 'form-group--error': $v.form.description.$error }">
        <b-form-textarea   v-model.trim="form.description" @input="setTouched('description')" type="text" required rows="3"
      max-rows="6" ></b-form-textarea>
         <b-form-invalid-feedback v-if="$v.form.description.$error" :state="$v.form.description.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
        <!-- input -->
    </b-col>
     <b-col cols="12"  class="text-center">
    <youtube :video-id="videoId"></youtube>
    </b-col>
</b-row>
<b-row>
    <b-col cols="12"  >
    <b-button type="submit" variant="info"><b-spinner v-if="isSendingForm" small></b-spinner>  {{$t('Save')}}</b-button>
    <b-button class="ml-3 mr-3" type="button" variant="danger" @click="deleteItem(main_id)"><b-spinner v-if="isSendingForm"  small></b-spinner>  {{$t('Delete')}}</b-button>
     </b-col>
</b-row>






    </b-form>
     </b-card>
</b-container>

</div>




</template>

<script>

import axios from 'axios'
import { required, numeric } from 'vuelidate/lib/validators'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import FormSkeleton from '@/components/skeletons/FormSkeleton.vue'
import {getIdFromUrl } from 'vue-youtube'
export default {
        data() {
      return {
        form: {
            name: null,
            url: null,
            description :null,
           
        },
        upvote:0,
        downvote:0,
        videoId:null,
        user:[],
        competition:[],
        main_id:null,
        previewImage:"",
        statuses: [ 'active', 'deactivated', 'waiting review'],
        roles:[],
        errorsBack:{},
        errorMgs:'',
        ShowError: false,
        show: true,
        ShowDoneAlert:false,
        isSendingForm:false,
        showForm:false,
        
      }
    },
    components:{
      DoneAlert,FormSkeleton,ErrorAlert
        
    },
     validations: {
      form:{
        url:{required},
        status: {  required },
        name: {  required },
        description: { required },
        upvote: { numeric },
        downvote: { numeric },
       
        rate: { numeric },
    
    }
  },
  /***************** */
      methods: {
      fillForm(Thedata){
        this.form.name = Thedata.name;
        this.form.url = Thedata.url;
        this.form.description = Thedata.description;
        this.form.upvote = Thedata.upvote;
        this.form.downvote = Thedata.downvote;
        this.form.rank = Thedata.rank;
        this.form.rate = Thedata.rate;
        this.form.status = Thedata.status;
        this.user = Thedata.user;
        this.competition = Thedata.competition;
    
        this.videoId =  getIdFromUrl(this.form.url) ;
        this.main_id= Thedata.id;

    //console.log("zxcxz-----------"+ this.form);


      },

     setTouched(theModel) {
       if(theModel == 'description' || theModel == 'all' ){this.$v.form.description.$touch()}
       if(theModel == 'url' || theModel == 'all' ){this.$v.form.url.$touch()}
       if(theModel == 'status' || theModel == 'all' ){this.$v.form.status.$touch()}
       if(theModel == 'name' || theModel == 'all' ){this.$v.form.name.$touch()}
       if(theModel == 'upvote' || theModel == 'all' ){this.$v.form.upvote.$touch()}
       if(theModel == 'downvote' || theModel == 'all' ){this.$v.form.downvote.$touch()}
       if(theModel == 'rank' || theModel == 'all' ){this.$v.form.rank.$touch()}
       if(theModel == 'rate' || theModel == 'all' ){this.$v.form.rate.$touch()}
    },
        fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
      getCreateData(){
      axios.get(this.$store.state.backendUrl+'api/competitor/'+this.$route.params.id+'/edit' , {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
           .then((response) => {
                console.log(response);
                if(response.Error){this.ShowError=true;this.errorMgs = this.$t('error_login');}
                else{
          
                  this.fillForm(response.data.success.competitor);
                  this.showForm=true;
               //  console.log(response.data.success);
                }
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
      onSubmit(event) {
        event.preventDefault()
        if (!this.$v.$invalid) {

        this.isSendingForm = true;
        this.ShowError=false;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
      
        axios.post(this.$store.state.backendUrl+'api/ar/competitor_update/'+this.main_id,formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
              
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  setTimeout(()=>{     
                  this.ShowDoneAlert = false;
                  }, 3000);
                  this.getCreateData();
                
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
           }
      },
     
      ResetForm() {
        
        // Reset our form values
       
        this.form.name = null,
        this.form.url = null,
         this.form.description = null,
        this.form.upvote = null,
        this.form.downvote = null,
        this.form.status = null,
        this.form.rank = null,
        this.form.rate = null,
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      deleteItem($id){
          var r = confirm(this.$t('Are you sure?'));
            if (r == true) {
           this.isSendingForm = true;
           this.ShowError=false;
           axios.post(this.$store.state.backendUrl+'api/ar/competitor_delete/'+$id ,{method:"DELETE"} ,{
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  setTimeout(()=>{     
                  this.ShowDoneAlert = false;
                  }, 3000);
                  this.$router.push('/competition/'+this.competition.slug)
            })
            .catch( (error) => {
               
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
          
            });
            } 
      }

    },
    /********************* */
    mounted() {
      
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
    }
    
}
</script>